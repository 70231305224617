.productsGrid{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 20px;
}

@media screen and (max-width: 1000px) {
    .productsGrid{
        grid-template-columns: repeat(3,1fr);
    }
}
@media screen and (max-width: 780px) {
    .productsGrid{
        grid-template-columns: repeat(2,1fr);
    }
}
@media screen and (max-width: 600px) {
    .productsGrid{
        grid-template-columns: repeat(2,1fr);
    }
}
@media screen and (max-width: 450px) {
    .productsGrid{
        grid-template-columns: repeat(1,1fr);
    }
}