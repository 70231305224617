.InvolvementCardAnimation{
    width: 28%;
}

@media screen and (max-width: 650px) {
    .InvolvementCardAnimation{
        width: 90%;
    }
    
}















/* ["90%", "28%", "28%", "28%"] */