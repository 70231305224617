.TeamGrid{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 20px;
}

@media screen and (max-width: 1200px) {
    .TeamGrid{
        grid-template-columns: repeat(3,1fr);
    }
}
@media screen and (max-width: 900px) {
    .TeamGrid{
        grid-template-columns: repeat(2,1fr);
    }
}
@media screen and (max-width: 650px) {
    .TeamGrid{
        grid-template-columns: repeat(1,1fr);
    }
}